
import Vue from "vue";
import { Component, Prop, Watch, Ref } from "vue-property-decorator";
import loginRecordList from "./components/loginRecordList.vue";
import { Pagination } from "ant-design-vue";
@Component({
  name: "loginRecord",
  components: { loginRecordList, [Pagination.name]: Pagination },
  filters: {
    //局部过滤器
  },
})
export default class loginRecord extends Vue {
  public loginRecordList: any[] = [];
  public total: number = 0;
  public current: number = 1;

  queryLoginLog() {
    this.$apis.loginLog(this.current).then((res: any) => {
      if (res.code === "200") {
        this.loginRecordList = res.data.list;
        this.total = res.data.total;
        this.current = res.data.pageNum;
      }
    });
  }

  handleChange(page: number) {
    this.current = page;
    this.queryLoginLog();
  }
  //props定义 需要依赖装饰器 @Prop({ default: '默认值', type: '数据类型' })
  //@Prop()
  //计算属性
  //get computedMsg(): string {
  //  return ''
  //}
  //数据监听 Watch('监听数据', { deep: Boolean | 深度监听, immediate: Boolean | 初始化调用 })
  //@Watch('')
  //ref 引用元素 Ref('ref')
  //@Ref()
  //创建前钩子函数
  created(): void {
    this.queryLoginLog();
  }
}
